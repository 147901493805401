import HomePage from "./Home";
import BackToTop from "./backToTop";

// import { Analytics } from "@vercel/analytics/react"
// import { SpeedInsights } from "@vercel/speed-insights/next"

export default function Home() {
  return (
    <>
      <HomePage />
      <BackToTop />

      {/* <Analytics />
      <SpeedInsights /> */}
    </>
  );
}
